import { SvgIcon } from "@mui/material";

export default function Smartphone(props) {
  return (
    <SvgIcon sx={{ fontSize: 16 }} viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M1.42857 16C1.03571 16 0.699405 15.8576 0.419643 15.5727C0.139881 15.2879 0 14.9455 0 14.5455V1.45455C0 1.05455 0.139881 0.712121 0.419643 0.427273C0.699405 0.142424 1.03571 0 1.42857 0H8.57143C8.96429 0 9.3006 0.142424 9.58036 0.427273C9.86012 0.712121 10 1.05455 10 1.45455V14.5455C10 14.9455 9.86012 15.2879 9.58036 15.5727C9.3006 15.8576 8.96429 16 8.57143 16H1.42857ZM1.42857 13.8182V14.5455H8.57143V13.8182H1.42857ZM1.42857 12.3636H8.57143V3.63636H1.42857V12.3636ZM1.42857 2.18182H8.57143V1.45455H1.42857V2.18182Z"
        fill="#0A66C2"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
